import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'dateButton',
    'abnDate',
    'date'
  ]

  applyDate() {
    $(this.dateButtonTarget).prop('disabled', true)
    $(this.dateButtonTarget).addClass('d-none')
    $(this.dateTarget).val(moment().format('MM/DD/YYYY'))
    $(this.abnDateTarget).removeClass('d-none')
    $(this.abnDateTarget).text($('#date').val())
  }
}