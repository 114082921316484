namespace('Momd', function (exports) {
    exports.FileAttachmentDropZone = class {
        constructor(dropZoneSelector) {
            let dropZoneTimeout;
            const dropZones = $(dropZoneSelector);
            const timeout = dropZoneTimeout;
            if (timeout) {
                clearTimeout(timeout);
            } else {
                dropZones.addClass('in');
            }

            const hoveredDropZone = $(event.target).closest(dropZones);
            dropZones.not(hoveredDropZone).removeClass('hover');
            hoveredDropZone.addClass('hover');
        }
    }
});

