import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'paginationSummary',
    'paIdsVisible',
    'form'
  ]

  connect() {
    this.initMomdViews()
    this.setupListeners()
    this.element.classList.remove('d-none')
  }

  setupListeners() {
    const controller = this

    $("body").on("ajax:complete", "#note-add-modal form", function(event, response) {
      const paID = $(event.target).data('patientAgreementId');
      if (response.status === 422) {
        $('#note-add-modal .modal-body').html(response.responseText);
        $('#note-add-modal #note_body').focus();
        return $('#note-add-modal').find('label.radio-inline:first-child').addClass("active");
      } else {
        $(`.well--card[data-patient-agreement-id=${paID}] .notes-section`).html(response.responseText);
        return $("#note-add-modal").modal("hide");
      }
    });

    $('#veripro-dashboard').on('update-pagination-summary', (evt, paymentMethodId) => controller.updateVeriproDashboardPaginationSummary(false));

    $('#veripro-dashboard').on('payment-method-list:remove', function(evt, paymentMethodId) {
      // remove payment method row
      const $paymentMethod = $(`[data-payment-method-id=${paymentMethodId}]`);
      const paTop = $paymentMethod.closest("[data-patient-agreement=top]");
      if ($(paTop).find("[data-payment-method=top]").length > 1) {
        $paymentMethod.closest("[data-payment-method=top]").remove();
      } else {
        $paymentMethod.closest("[data-patient-agreement=top]").remove();
      }

      return controller.updateVeriproDashboardPaginationSummary(true);
    });
  }

  showMore(event) {
    event.preventDefault()
    const elementsWithDataId = document.querySelectorAll('[data-patient-agreement-id].well')
    const paIdsShown = Array.from(elementsWithDataId).map(element => element.getAttribute('data-patient-agreement-id'))
    this.paIdsVisibleTarget.value = _.uniq(paIdsShown).join(',')

    $(this.formTarget).trigger('submit.rails')
  }

  addNote() {
    const path = $(event.target).data('updateNoteUrl');
    const paID = $(event.target).data('patientAgreementId');
    $('#note-add-modal .note_body .error').text('');
    $("#note-add-modal").find("[name='note[body]']").val('');
    $("#note-add-modal").find("[name='note[note_type]'][value='internal']").closest("label").click();
    $("#note-add-modal").find('form').attr('action', path);
    return $("#note-add-modal").find('form').data('patientAgreementId', paID);
  }

  viewAllNotes() {
    const $parent = $(event.target).closest('.well--card');
    $parent.find(".first-note").hide();
    $parent.find(".all-notes").show();
    $parent.find(".view-all-notes").hide();
    return $parent.find(".collapse-all-notes").show();
  }

  collapseAllNotes() {
    const $parent = $(event.target).closest('.well--card');
    $parent.find(".first-note").show();
    $parent.find(".all-notes").hide();
    $parent.find(".view-all-notes").show();
    return $parent.find(".collapse-all-notes").hide();
  }

  initMomdViews() {
    new Momd.Views.Dashboards.VeriproDashboardPaymentMethod({el: "#veripro-dashboard"});
    new Momd.Views.Dashboards.VeriproDashboardChargeForm({el: "#veripro-dashboard"});
    new Momd.Views.Dashboards.Charges.VeriproDashboardAuthNet("#veripro-dashboard");
    new Momd.Views.Dashboards.Charges.VeriproDashboardExternalPayment("#veripro-dashboard");
    new Momd.Views.Dashboards.Charges.VeriproDashboardHealthIpass("#veripro-dashboard");
  }

  updateVeriproDashboardPaginationSummary(decrement) {
    const $contentSummary = $("#veripro-dashboard .content__summary");
    const newNumPas = $('.well--card[data-patient-agreement-id]').length;
    let newTotalPas = Number($contentSummary.attr("data-total-pas"));
    if (decrement) {
      newTotalPas = newTotalPas - 1;
    }
    if(newTotalPas < newNumPas) {
      newTotalPas = newNumPas
    }
    const newNumPasNoun = newNumPas === 1 ? "verified patient agreement" : "verified patient agreements";
    this.paginationSummaryTarget.innerHTML = `Displaying ${newNumPas} out of ${newTotalPas} ${newNumPasNoun} ready for payment to be taken from the past 5 days`
    $contentSummary.attr("data-num-pas", newNumPas);
    $contentSummary.attr("data-total-pas", newTotalPas);

    if (newNumPas === 0 && newTotalPas != 0) {
      return $("#show-more-veripro-dashboard:visible > input").trigger('click');
    }
  };
}
