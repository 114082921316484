import { Application } from '@hotwired/stimulus';

import ProviderAssignmentLookupModalController from "./provider_assignment_lookup_modal_controller.js"
import NewBatchesController from "./new_batches_controller.js"
import SupportTicketController from "./support_ticket_controller.js"
import LiveChatController from "./live_chat_controller.js"
import VeriproDashboardController from "./veripro_dashboard_controller.js"
import PatientAgreementController from "./patient_agreements/patient_agreement_controller.js"
import PAMedicalInfoController from "./patient_agreements/medical_info_controller.js"
import PAPatientDemographicsController from "./patient_agreements/patient_demographics_controller.js"
import PAArchiveController from "./patient_agreements/archive_controller.js"
import PAProductsFormController from "./patient_agreements/patient_agreement_products_form_controller.js"
import PAProductSelectionController from "./patient_agreements/patient_agreement_product_selection_controller.js"
import PAFileAttachmentController from "./patient_agreements/file_attachments_controller.js"
import MobileAttachmentsController from './patient_agreements/mobile_attachments_controller.js';
import PAMotionIQController from "./patient_agreements/motion_iq_controller.js"
import PAReadyForBillingController from "./patient_agreements/ready_for_billing_controller.js"
import PANotesController from "./patient_agreements/notes_controller.js"
import VP2EditCoverageController from "./VP2/edit_coverage_controller.js"
import VP2ViewCoverageController from "./VP2/view_coverage_controller.js"

import SavedSearchesActionsController from "./saved_searches/actions_controller.js"
import SavedSearchesFormController from "./saved_searches/form_controller.js"
import SavedSearchesSelectorController from "./saved_searches/selector_controller.js"
import Select2Controller from "./select2_controller.js"
import TooltipController from "./tooltip_controller.js"
import EwPopoverController from "./early_warnings_popover_controller.js"

import AnnouncementsController from "./announcements_controller.js"
import AdvanceBeneficiaryNoticeController from "./advance_beneficiary_notice_controller.js"
import AbnSignatureController from "./abn_signature_controller.js"
import PAAbnController from "./patient_agreements/abn_controller.js"
import TestReadinessController from "./test_readiness_controller.js"
import DiagnosesSearchController from "./diagnoses_search_controller.js"
import ProductsSearchController from "./products_search_controller.js"
import ProductsSearchAutoSelectController from "./products_search_auto_select_controller.js"

import DisableController from "./disable_controller.js"
import HighchartsLiveDashboardController from "./highcharts_live_dashboard_controller.js"
import ModalController from "./modal_controller.js"
import PaymentMethodSectionController from "./patient_agreements/payment_method_section_controller.js"
import PaymentMethodCardController from "./patient_agreements/payment_method_card_controller.js"
import ChargeFormController from "./patient_agreements/charge_form_controller.js"
import AuthNetController from "./patient_agreements/auth_net_controller.js"
import HealthIpassController from "./patient_agreements/health_ipass_controller.js"
import ExternalPaymentController from "./patient_agreements/external_payment_controller.js"
import BarcodeScannerController from "./barcode_scanner_controller.js"
import ModalLinksController from "./modal_links_controller.js"
import PASignaturesController from "./patient_agreements/signatures_controller.js"
import PAAttachmentsController from "./patient_agreements/attachments_controller.js"
import TurboFileUploader from "./turbo_file_uploader_controller.js"
import PaymentMethodModalController from "./patient_agreements/payment_method_modal_controller.js"
import EncounterSelectController from "./encounter_select_controller.js"
import PatientAgreementCustomFormsController from "./patient_agreements/custom_forms_controller.js"
import PatientAgreementDropshipAlertController from "./patient_agreements/dropship_alert_controller.js"
import PAPatientSearchController from "./patient_agreements/patient_search_controller.js"
import PACreationFormController from "./patient_agreements/creation_form_controller.js"
import PAExpandoController from "./patient_agreements/expando_controller.js"

import ClipboardUrlController from "./clipboard_url_controller.js"
import CurrentLocationModalController from "./current_location_modal_controller.js"
import NewProviderModalController from "./new_provider_modal_controller.js"
import Veripro2VisitController from "./vp2/visit_controller.js"

window.Stimulus = Application.start()

Stimulus.register("provider-assignment-lookup-modal", ProviderAssignmentLookupModalController)
Stimulus.register("new-batches", NewBatchesController)
Stimulus.register("support-ticket", SupportTicketController)
Stimulus.register("live-chat", LiveChatController)
Stimulus.register("veripro-dashboard", VeriproDashboardController)
Stimulus.register("saved-searches-actions", SavedSearchesActionsController)
Stimulus.register("saved-searches-form", SavedSearchesFormController)
Stimulus.register("saved-searches-selector", SavedSearchesSelectorController)
Stimulus.register("patient-agreement", PatientAgreementController)
Stimulus.register("pa-medical-info", PAMedicalInfoController)
Stimulus.register("pa-patient-demographics", PAPatientDemographicsController)
Stimulus.register("pa-archive", PAArchiveController)
Stimulus.register("pa-products-form", PAProductsFormController)
Stimulus.register("pa-product-selection", PAProductSelectionController)
Stimulus.register("pa-file-attachment", PAFileAttachmentController)
Stimulus.register("pa-motion-iq", PAMotionIQController)
Stimulus.register("pa-ready-for-billing", PAReadyForBillingController)
Stimulus.register("vp2-edit-coverage", VP2EditCoverageController)
Stimulus.register("vp2-view-coverage", VP2ViewCoverageController)
Stimulus.register("select2", Select2Controller)
Stimulus.register("tooltip", TooltipController)
Stimulus.register("ew-popover", EwPopoverController)
Stimulus.register("announcements", AnnouncementsController)
Stimulus.register("advance-beneficiary-notice", AdvanceBeneficiaryNoticeController)
Stimulus.register("abn-signature", AbnSignatureController)
Stimulus.register("pa-abn", PAAbnController)
Stimulus.register("test-readiness", TestReadinessController)
Stimulus.register("diagnoses-search", DiagnosesSearchController)
Stimulus.register("products-search", ProductsSearchController)
Stimulus.register("products-search-auto-select", ProductsSearchAutoSelectController)
Stimulus.register("disable", DisableController)
Stimulus.register("highcharts-live-dashboard", HighchartsLiveDashboardController)
Stimulus.register("modal", ModalController)
Stimulus.register("payment-method-section", PaymentMethodSectionController)
Stimulus.register("payment-method-card", PaymentMethodCardController)
Stimulus.register("charge-form", ChargeFormController)
Stimulus.register("auth-net", AuthNetController)
Stimulus.register("health-ipass", HealthIpassController)
Stimulus.register("external-payment", ExternalPaymentController)
Stimulus.register("barcode-scanner", BarcodeScannerController)
Stimulus.register("modal-links", ModalLinksController)
Stimulus.register("pa-signatures", PASignaturesController)
Stimulus.register("pa-attachments", PAAttachmentsController)
Stimulus.register("turbo-file-uploader", TurboFileUploader)
Stimulus.register("payment-method-modal", PaymentMethodModalController)
Stimulus.register("encounter-select", EncounterSelectController)
Stimulus.register("pa-notes", PANotesController)
Stimulus.register("mobile-attachments", MobileAttachmentsController)
Stimulus.register("pa-custom-forms", PatientAgreementCustomFormsController)
Stimulus.register("dropship-alert", PatientAgreementDropshipAlertController)
Stimulus.register("pa-patient-search", PAPatientSearchController)
Stimulus.register("pa-creation-form", PACreationFormController)
Stimulus.register("pa-expando", PAExpandoController)
Stimulus.register("clipboard-url", ClipboardUrlController)
Stimulus.register("current-location-modal", CurrentLocationModalController)
Stimulus.register("new-provider-modal", NewProviderModalController)
Stimulus.register("vp2-visits-show", Veripro2VisitController)
