import { put } from '@rails/request.js'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'checkbox'
  ]

  connect() {}

  updateCheckboxVisibility(event) {
    let confirmMessage = "Proceed with attaching as Patient Visible?\nIf patient selects the Email option for their receipt, this document will be included in the email."

    if (this.checkboxTarget.checked) {
      if (confirm(confirmMessage)) {
        this.updateVisibility(this.checkboxTarget.dataset.patientAgreementId, this.checkboxTarget.dataset.fileId, this.checkboxTarget.checked);
      } else {
        this.checkboxTarget.checked = false;
      }
    } else {
      this.updateVisibility(this.checkboxTarget.dataset.patientAgreementId, this.checkboxTarget.dataset.fileId, this.checkboxTarget.checked);
    }
  }

  updateVisibility(patientAgreementId, fileId, patientVisible) {
    let fetchParams = `patient_visible=${patientVisible}`

    put(`/admin/patient_agreements/${patientAgreementId}/v2/file_attachments/${fileId}/set_visibility?${fetchParams}`, {
      responseKind: "turbo-stream"
    }).then(() => {
      BigSpinner.finished();
    })
  }
}
