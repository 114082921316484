/*
  Big Spinner

  The Big Spinner provides status notification to the user about asynchronous updates.

*/

BigSpinner = {
  finishedTimer: null,

  disabled: false,

  disableOnce: function () {
    this.disabled = 'once';
  },

  enableOnce: function () {
    this.disabled = false;
  },

  add: function () {
    if (BigSpinner.disabled === 'once') {
      BigSpinner.disabled = false;
      return;
    }

    var existing_spinner = $('#big_spinner');

    if (existing_spinner) {
      window.clearTimeout(BigSpinner.finishedTimer);
      $('#big_spinner').remove();
    }

    var body = document.getElementsByTagName('body')[0];
    var spinnerStyle = '';

    //If the user is running IE < 7, we need to engage in some hackery.
    if (!(window.XMLHttpRequest)) {
      /*
        Since IE < 7 doesn't support position: fixed, we need to fake it.
        For our purposes here, we don't care if the spinner follows the viewport,
        we only care that it gets placed at the middle of the viewport initially.
      */

      if (document.documentElement && document.documentElement.clientHeight) {
        // Explorer 6 strict mode
        var height = document.documentElement.clientHeight;
      }
      else if (document.body) {
        // Explorer 6 quirks mode, and earlier Explorers
        var height = document.body.clientHeight;
      }

      var offset = document.documentElement.scrollTop + (height / 2) - 64;

      spinnerStyle = 'position: absolute; top: ' + String(offset) + 'px;';
    }

    var spinner = "<div id='big_spinner' class='big_spinner_waiting' style='" + spinnerStyle + "' role='alert' aria-live='assertive' aria-label='Page Loading'><i class='fa fa-spinner fa-spin bootstrap-green'></i></div>";

    $(body).append(spinner);
  },

  finished: function() {
    $('#big_spinner').remove();
  }
};

$(document).ajaxStart(BigSpinner.add);
$(document).ajaxComplete(BigSpinner.finished);
