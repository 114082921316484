import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'coverage',
    'otherTextOptional',
    'sos',
    'sosDropdown',
    'otherDenial',
    'noCoverageDiv',
    'sosReasonsContainer',
    'otherReasonContainer',
    'noCoverageReasons'
  ]

  connect() {
    $(this.sosDropdownTarget).on('select2:select', function () {
      let event = new Event('change')
      this.dispatchEvent(event);
    })
    this.denialReasons()
    this.noCoverageValidation()
  }

  noCoverageValidation() {
    if ($('#other-Other').is(':checked')) {
      $(this.noCoverageDivTarget).removeClass('d-none')
    } else {
      $(this.otherTextOptionalTarget).val('')
      $(this.noCoverageDivTarget).addClass('d-none')
    }
  }

  denialReasons() {
    if (this.sosTarget.checked) {
      $(this.sosReasonsContainerTarget).removeClass('d-none')
    } else {
      $(this.sosReasonsContainerTarget).addClass('d-none')
    }

    if (this.otherDenialTarget.checked) {
      $(this.otherReasonContainerTarget).removeClass('d-none')
    } else {
      $(this.otherReasonContainerTarget).addClass('d-none')
      $(this.otherReasonContainerTarget).val('')
    }

    if (this.coverageTarget.checked) {
      $(this.noCoverageReasonsTarget).removeClass('d-none')
    } else {
      $(this.noCoverageReasonsTarget).addClass('d-none')
    }

    $(this.sosDropdownTarget).on("change", function (e) {
      if ($('#select2-sos-reasons-container').text() == 'Other') {
        $('#sos-other-reason').removeClass('d-none')
      } else {
        $('#sos-other-reason').addClass('d-none')
        $('#sos-other-reason').val('')
      }
    })
  }
}