import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'savedSearchSelector'
  ]
  static outlets = [
    'saved-searches-form'
  ]

  connect() {
    $(this.savedSearchSelectorTarget).on('select2:select', function () {
      let event = new Event('change')
      this.dispatchEvent(event);
    });
  }

  selectSavedSearch() {
    if (event.currentTarget.value) {
      window.location.href = `saved_searches/${event.currentTarget.value}`
    } else {
      this.savedSearchesFormOutlet.prepareParams()
      window.location.href = `${window.location.pathname}?${this.savedSearchesFormOutlet.urlParams.toString()}`
    }
  }
}
