import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    contentChart: Object,
    typeChart: String
  }

  connect() {
    this.processData();
    this.generateChart();
  }

  generateChart() {
    Highcharts.chart(this.element.id, this.dataChart);
  }

  processData() {
    let default_data = {
      chart: { type: this.typeChartValue },
      title: { text: undefined },
      legend: { enabled: false },
      exporting: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        series: {
          dataLabels: { enabled: false }
        }
      },
    }
    let custom_data = {}

    switch (this.typeChartValue) {
      case 'treemap':
        custom_data = this.dataTreemap()
        break
      case 'pie':
        custom_data = this.dataPie()
        break
      case 'bar':
        custom_data = this.dataBar()
        break
    }

    this.dataChart = {...default_data, ...custom_data}
  }

  dataTreemap() {
    let heatmap_series = [];

    for(var option in this.contentChartValue) {
      heatmap_series.push({ name: option, value: parseInt(this.contentChartValue[option]) });
    }

    return {
      colorAxis: {
        minColor: '#FFF',
        maxColor: '#C8102E'
      },
      plotOptions: {
        series: {
          dataLabels: { enabled: true }
        }
      },
      series: [{
        layoutAlgorithm: 'squarified',
        clip: false,
        data: heatmap_series
      }],
    }
  }

  dataPie() {
    let browser_series_data = [];

    for(var option in this.contentChartValue) {
      browser_series_data.push({ name: option, y: parseInt(this.contentChartValue[option]) });
    }

    return {
      tooltip: { pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b><br>Count: {point.y}' },
      accessibility: { point: { valueSuffix: '%' } },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },
      series: [{
        name: 'Users',
        colorByPoint: true,
        data: browser_series_data
      }],
    }
  }

  dataBar() {
    let platform_series_data = [];
    let platform_x_axis = [];
    let maxValue = 0;

    for(var option in this.contentChartValue) {
      platform_x_axis.push(option)
      platform_series_data.push(parseInt(this.contentChartValue[option]));
      maxValue = maxValue + parseInt(this.contentChartValue[option]);
    }

    return {
      tooltip: { pointFormat: 'Count: <b>{point.y}</b>' },
      xAxis: { categories: platform_x_axis },
      yAxis: {
        title: { enabled: false },
        max: maxValue
      },
      series: [{
        name: 'Count',
        colorByPoint: true,
        data: platform_series_data
      }],
    }
  }
}
