import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['mrn', 'refreshFromEmrButton']

  connect() {
    this.updateRefreshEmrHref()
  }

  updateRefreshEmrHref() {
    if (!this.hasMrnTarget) { return }

    if (this.mrnTarget.value) {
      this.refreshFromEmrButtonTarget.classList.remove("disabled")
      let path = this.refreshFromEmrButtonTarget.dataset.path
      path = path.replace(/%3CMRN%3E/, this.mrnTarget.value)
      this.refreshFromEmrButtonTarget.href = path
    }
  }
}
