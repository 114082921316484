window.getPusher = function() {
    var csrf_token = document.querySelector('[name=csrf-token]').content;
    return new Pusher(window.PUSHER_APP_KEY, {encrypted: true, auth: {headers: { 'X-CSRF-Token': csrf_token }}});
}

// TODO: convert this to ES6 when it is implemented
window.PusherConnectionCheck = function(pusher, callback) {
    this.pusher = pusher;
    this.callback = callback;
    this.firstConnect = pusher.connection.state === 'initial' || pusher.connection.state === 'connecting';
    this.connected = pusher.connection.state === 'connected';
    // Keep listeners from stealing `this` on callbacks
    for (var prop in PusherConnectionCheck.prototype) {
        if (typeof this[prop] === 'function') {
            this[prop] = this[prop].bind(this);
        }
    }
    this.bindListeners();
}

window.PusherConnectionCheck.prototype = {
    bindListeners: function() {
        var connection = this.pusher.connection;
        connection.bind('failed', this.onFailed);
        connection.bind('unavailable', this.onFailed);
        connection.bind('connecting', this.onConnecting);
        connection.bind('connected', this.onConnect);
        window.addEventListener('beforeunload', this.onUnload);
    },

    onConnect: function() {
        if (!this.firstConnect) {
            this.delayedCallback();
        }
        this.firstConnect = false;
        this.clearInterval();
    },

    onConnecting: function() {
        this.connected = false;
        if (this.firstConnect)
            return;
        this.delayedCallback();
        this.setInterval();
    },

    onFailed: function() {
        this.connected = false;
        this.firstConnect = false;
        this.doCallback();
        this.setInterval();
    },

    onUnload: function() {
        window.clearTimeout(this.callbackTimeout);
    },

    // Allows for checking if unloading the page
    // Pusher sometimes fires `connected` and `connecting` events on page unload
    delayedCallback: function() {
        window.clearTimeout(this.callbackTimeout);
        this.callbackTimeout = window.setTimeout(this.doCallback, 500);
    },

    doCallback: function() {
        if (document.hidden)
            return;
        if (this.callback() === true) {
            this.clear();
        }
    },

    setInterval: function() {
        this.clearInterval();
        this.interval = window.setInterval(this.doCallback, 15000);
    },

    clearInterval: function() {
        if (this.interval)
            window.clearInterval(this.interval);
    },

    clear: function() {
        this.clearInterval();
        var connection = this.pusher.connection;
        connection.unbind('failed', this.onFailed);
        connection.unbind('unavailable', this.onFailed);
        connection.unbind('connecting', this.onConnecting);
        connection.unbind('connected', this.onConnect);
        window.removeEventListener('beforeunload', this.onUnload);
    }
}



$(function() {
    $('.sort-header').click(function(e) {
        e.preventDefault();
        var $this = $(this);
        var url = new URL(window.location);
        var new_dir = $this.data('direction') === 'asc' ? 'desc' : 'asc';
        $this.data('direction', new_dir);
        url.searchParams.set('direction', new_dir);
        url.searchParams.set('sort_by', $this.data('column'));
        location.href = url.toString();
    });
});