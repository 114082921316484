import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { 
    allProducts: Number
  }
  static targets = [
    'selectProductBtn'
  ];

  connect() {
    this.productAutoTarget()
  }

  productAutoTarget() {
    if (this.allProductsValue === 1) {
      setTimeout(() => {
        this.selectProductBtnTarget.click()
      }, 150);
    }
  }
}