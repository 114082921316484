// Put polyfills first just in case other libraries rely on them.
import { default as ResizeObserverPolyfill } from 'resize-observer-polyfill';
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPolyfill;
}

import {default as jQuery} from 'jquery'
window.jQuery = window.$ = jQuery

import _ from 'underscore'

_.mixin({
  isBlank(string) {
    return _.isUndefined(string) || _.isNull(string) || (string.trim().length === 0);
  }
});

_.mixin({
  serializeForm(form) {
    const arr = form.serializeArray();
    return _(arr).reduce((function(acc, field) {
      acc[field.name] = field.value;
      return acc;
    }), {});
  }});

_.mixin({
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
  }
});

_.mixin({
  isNumGreaterThanOrEqualTo(lowerLimit, number) {
    const num = parseInt(number);

    // isNumber returns true for NaN, hence the separate
    // check for NaN and Number
    if (_.isNaN(num) || !_.isNumber(num)) {
      return false;
    } else {
      return num >= lowerLimit;
    }
  }
});

const isIE = false || !!document.documentMode;
if (!isIE) {
  _.mixin({
    beep: (function() {
      const ctx = new ((window.AudioContext || window.webkitAudioContext));
      return function(duration, type, frequency) {
        duration = +duration;
        const osc = ctx.createOscillator();
        osc.type = type;
        osc.frequency.value = frequency;
        osc.connect(ctx.destination);
        osc.start();
        setTimeout((function() {
          osc.stop();
        }), duration);
      };
    })()
  });
}

window._ = _

import * as Backbone from 'backbone'
window.Backbone = Backbone

import * as Handlebars from 'handlebars'
window.Handlebars = Handlebars

window.HandlebarsTemplates = {}
import('@app/templates/file_attachments/upload-manager-file.hbs')
import('@app/templates/file_attachments/upload-manager-main.hbs')
import('@app/templates/mass_entry/form.hbs')
import('@app/templates/mass_entry/row.hbs')
import('@app/templates/mass_entry/updated_row.hbs')
import('@app/templates/patient_agreements/diagnosis_row.hbs')
import('@app/templates/patient_agreements/modals/_selected_inventory_item.hbs')
import('@app/templates/patient_agreements/modals/change_product_modal.hbs')
import('@app/templates/protocols/diagnosis_checkbox.hbs')

import * as Marionette from '@vendor/backbone.marionette.min'
window.Marionette = window.Backbone.Marionette = Marionette

import Highcharts from 'highcharts'
import HighchartsTreemap from 'highcharts/modules/treemap'
HighchartsTreemap(Highcharts)
import HighchartsHeatmap from 'highcharts/modules/heatmap'
HighchartsHeatmap(Highcharts)
import HighchartsExporting from 'highcharts/modules/exporting'
HighchartsExporting(Highcharts)
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting'
HighchartsOfflineExporting(Highcharts)
import HighchartsData from 'highcharts/modules/data'
HighchartsData(Highcharts)
import HighchartsExportData from 'highcharts/modules/export-data'
HighchartsExportData(Highcharts)
window.Highcharts = Highcharts

import {default as select2} from '@vendor/select2-4.0.13'
select2($)

window.namespace = function(target, name, block) {
  if (arguments.length < 3) { [target, name, block] = Array.from([window, ...arguments]); }
  const top    = target;
  for (let item of Array.from(name.split('.'))) { target = target[item] || (target[item] = {}); }
  return block(target, top);
};

import Moment from 'moment'
window.moment = Moment
