import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ['modal', 'newLocation', 'locationError']

  focusOnNewLocation() {
    this.newLocationTarget.focus()
  }

  updateLocation(event) {
    event.preventDefault()
    const select = this.newLocationTarget
    const selectedOption = select.options[select.selectedIndex]
    const locationName = selectedOption.text
    const url = this.modalTarget.dataset.updateCurrentLocationPath

    const formData = new FormData()
    formData.append('new_location', locationName)

    post(url, {
      body: formData,
      responseKind: "turbo-stream"
    })
  }
}
