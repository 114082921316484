namespace('Momd', function(exports) {
  // Due to the universal nature of the requests, this needs to be used as a singleton
  exports.TopazSignature = class TopazSignature {

    _baseUri = null;
    _installed = false;
    _initialized = false;
    _tabletEnabled = 0;
    _dataPoints = 0;
    _opts;
    _canvas;
    _clearing = false;
    _listening = false;

    onchange;

    get initialized() {
      return this._initialized;
    }

    get installed() {
      return this._installed;
    }

    get tabletEnabled() {
      return this._tabletEnabled;
    }

    constructor(checkDelay = 200) {
      const protocol = location.protocol === 'file:' ? 'http:' : location.protocol;
      const port = protocol === 'https:' ? 47290 : 47289;
      this._baseUri = `${protocol}//tablet.sigwebtablet.com:${port}/SigWeb/`;
      this._opts = {checkDelay, x: 0, y: 0};
    }

    async initialize() {
      if (this._initialized)
        return;
      try {
        const data = await this._getProp('TabletState');
        this._initialized = true;
        this._installed = `${data}` !== '';
        this._tabletEnabled = !!data;
      } catch (e) {
        // Server returns no response if software is not installed
        if (e?.readyState === 0) {
          this._initialized = true;
          this._installed = false;
          this._tabletEnabled = false;
        } else {
          throw e;
        }
      }
    }

    async initializeCanvas(canvas, width = 500, height = 100, x = 0, y = 0, penWidth = 10) {
      await this.initialize();
      if (!this._installed)
        return;
      this._canvas = canvas;
      this._canvasContext = canvas?.getContext('2d');
      if (canvas) {
        await this.setDisplaySize(width, height);
        this.setDisplayPosition(x, y);
        await this.clearSignature();
        await this.setDisplayPenWidth(penWidth);
        await this.startCapture();
      } else {
        await this.stopCapture();
      }
    }

    async resizeImage(width = 500, height = 100, x = 0, y = 0) {
      await this.setDisplaySize(width, height);
      this.setDisplayPosition(x, y);
    }

    async checkTabletState() {
      await this.initialize();
      if (!this._installed)
        return false;
      return this._tabletEnabled = !!(await this._getProp('TabletState'));
    }

    async clearSignature() {
      await this.initialize();
      if (!this._installed) return;
      this._clearing = true;
      await this._getProp('ClearSignature');
      // Prevents race conditions on redraws
      setTimeout(() => this._clearing = false, 200);
    }

    setDisplayPosition(x, y) {
      this._opts.x = x;
      this._opts.y = y;
    }

    async setDisplaySize(width, height) {
      await this.initialize();
      if (!this._installed)
        return;
      await this._setProp('DisplayXSize', width);
      await this._setProp('DisplayYSize', height);
    }

    async setDisplayPenWidth(width) {
      await this.initialize();
      if (!this._installed)
        return;
      await this._setProp('DisplayPenWidth', width)
    }

    async _getProp(prop, opts = {}) {
      Object.assign(opts, {
        url: `${this._baseUri}/${prop}?noCache=${new Date().getTime()}`,
        type: 'GET'
      });
      return await $.ajax(opts);
    }

    async _getBinaryProp(prop) {
      const response = await fetch(`${this._baseUri}/${prop}?noCache=${new Date().getTime()}`);
      return await response.blob();
    }

    async _setProp(prop, value) {
      return await $.ajax({url: `${this._baseUri}/${prop}/${value}`, type: 'POST'});
    }

    _refreshImage = async () => {
      if (!this._canvas) {
        return;
      }
      const dataPoints = await this._getProp('TotalPoints', {global: false});
      if (dataPoints === this._dataPoints)
        return;
      this._dataPoints = dataPoints;
      await this._drawImage();
    }

    _drawImage = async () => {
      const response = await this._getBinaryProp('SigImage/0');
      if (!response)
        return;
      const image = await this._loadImage(response);
      this._canvasContext.drawImage(this._convertWhiteToTransparent(image), this._opts.x, this._opts.y);
      URL.revokeObjectURL(image.src);
      if (!this._clearing)
        this.onchange && this.onchange(this);
    }

    _convertWhiteToTransparent(image) {
      const imageCanvas = document.createElement('canvas');
      imageCanvas.height = image.height;
      imageCanvas.width = image.width;
      const imageContext = imageCanvas.getContext('2d');
      imageContext.drawImage(image, 0, 0);
      const imageData = imageContext.getImageData(0, 0, image.width, image.height);
      const pixels = imageData.data;
      for (let i = 0, n = pixels.length; i < n; i += 4) {
        if (pixels[i] > 245 && pixels[i + 1] > 245 && pixels[i + 2] > 245)
          pixels[i + 3] = 0;
      }
      imageContext.putImageData(imageData, 0, 0);
      return imageCanvas;
    }

    _loadImage(imageData) {
      return new Promise(callback => {
        const image = new Image();
        image.src = URL.createObjectURL(imageData);
        image.onload = () => {
          callback(image)
        };
      });
    }

    // We're using `setTimeout` so that we don't accidentally get overlapping requests
    _cycleRefreshImage = async () => {
      if (!this._listening) {
        return;
      }
      await this._refreshImage();
      setTimeout(this._cycleRefreshImage, this._opts.checkDelay)
    }

    startCapture = async () => {
      await this._setProp('TabletState', 1);
      if (await this.checkTabletState()) {
        this._listening = true;
        setTimeout(this._cycleRefreshImage, this._opts.checkDelay);
      }
    }

    stopCapture = async () => {
      this._listening = false;
      await this._setProp('TabletState', 0);
      await this.checkTabletState();
    }

  }

  exports.Topaz = new exports.TopazSignature();
  exports.Topaz.initialize().catch(e => console.error('TopazSignature failed to initialize: ', e));
});
