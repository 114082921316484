import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    "criteriaInput",
    "resultsHeader",
    "patientIdInput",
    "paExistsWarning",
    "searchContainer",
    "resultsLoaded",
    "selectedPatient",
  ]

  connect() {
    this.navKey, this.getCheck = null
    this.element.classList.remove('d-none')
  }

  handleKeyDown(event) {
    this.navKey = (event.which >= 37 && event.which <= 40)
    if (this.navKey) {
      this.getCheck = $("input:radio[name='" + event.target.name + "']:checked")[0]
    } else if (event.which == 13) {
      event.preventDefault()
      event.stopPropagation()
      $(event.target).click()
    }
  }

  handleKeyUp(event) {
    this.navKey = null
    this.getCheck = null
  }

  resultsLoadedTargetConnected(_element) {
    document.getElementById('turbo-patient-results').scrollIntoView({ behavior: 'smooth' });
  }

  selectedPatientTargetConnected(element) {
    this.searchContainerTarget.classList.add('d-none')
    this._selectPatient(element)
  }

  search(event) {
    this._lookupPatient();
  }

  searchForceEmrLookupFromClick(event) {
    this._lookupPatient(true);
  }

  selectPatient(event) {
    if (this.navKey) {
      event.preventDefault()
      event.stopPropagation()
      if (this.getCheck) {
        this.getCheck.checked = true
      } else {
        event.target.checked = false
      }
    } else {
      let card = event.target.closest('.card')
      this._selectPatient(card)
    }
  }

  deselectPatient(event) {
    event.preventDefault()
    this._deselectPatient()
  }

  openNewPatientModal(event) {
    get(event.target.dataset.url, {
      query: {
        criteria: this.criteriaInputTarget.value
      }
    })
  }

  _deselectPatient() {
    this._removeExistingPAWarning();
    let patient_id = this.patientIdInputTarget;
    document.getElementById('turbo-patient-results').innerHTML = '';
    patient_id.value = '';
    patient_id.dispatchEvent(new Event('change'));
    this.searchContainerTarget.classList.remove('d-none')
    let previous_search = this.criteriaInputTarget.value
    this.criteriaInputTarget.value = ''
    this.criteriaInputTarget.value = previous_search
    this.criteriaInputTarget.focus()
  }

  _selectPatient(card) {
    const patient_id = card.dataset.patientId;
    if( this.patientIdInputTarget.value != patient_id ) {
      this.patientIdInputTarget.value = patient_id
      this._removeExistingPAWarning();
      this._checkForExistingPAforPatient(patient_id);
      this.patientIdInputTarget.dispatchEvent(new Event('change'));
    }
  }

  _checkForExistingPAforPatient(patient_id) {
    BigSpinner.add()
    const path = `/admin/patients/${patient_id}/find_existing_patient_agreement_for_patient_v2`;
    get(path, {
      responseKind: "turbo-stream"
    }).then(() => {
      BigSpinner.finished()
    })
  }

  _lookupPatient(forceEmrLookup = false) {
    const criteria = this.criteriaInputTarget.value;
    const clinicId = $("#patient_agreement_clinic_id").val();
    const path = '/admin/patients/find_for_patient_agreement';
    get(path, {
      query: {
        criteria: criteria,
        clinic_id: clinicId,
        force_emr_lookup: forceEmrLookup
      },
      responseKind: "turbo-stream"
    })
  }

  _removeExistingPAWarning() {
    this.paExistsWarningTarget.innerHTML = ''
  }
}
