// // This is a coffeescript version of app/assets/javascripts/controllers/select_controller.js
// // Any changes to this file should also be made there.
var Dropdown;

Dropdown = (function() {
  class Dropdown {
    constructor() {
      this.optionCount = this.optionCount.bind(this);
      this.hasEmptyOption = this.hasEmptyOption.bind(this);
      this.hasPlaceholder = this.hasPlaceholder.bind(this);
      this.hasRequired = this.hasRequired.bind(this);
      this.hasAriaLabel = this.hasAriaLabel.bind(this);
      this.hasOptions = this.hasOptions.bind(this);
      this.hasTypeahead = this.hasTypeahead.bind(this);
      this.hasClear = this.hasClear.bind(this);
      this.hasGroups = this.hasGroups.bind(this);
      this.showEmptyOption = this.showEmptyOption.bind(this);
      this.insideModal = this.insideModal.bind(this);
      this.forceTypeahead = this.forceTypeahead.bind(this);
      this.forceNoTypeahead = this.forceNoTypeahead.bind(this);
      this.initDropdowns();
      $(document).on("ajaxComplete", () => {
        return this.initDropdowns();
      });
      $(document).on("shown.bs.modal", () => {
        return this.initDropdowns();
      });
      $(document).on("initDropdowns", () => {
        return this.initDropdowns();
      });
      // On first focus open the menu. This will need verified for future versions of select2, working well on 4.0.13
      $(document).on('focus', '.select2-selection.select2-selection--single', function(e) {
        // :enabled is important so tabbing isn't allowed on disabled, and prev is important because of grouped dropdowns like birthdate
        return $(this).closest('.select2-container').prev('select:enabled').select2('open');
      });
      $('select.select2').on('select2:closing', function(e) {
        return $(e.target).data('select2').$selection.one('focus focusin', function(e) {
          return e.stopPropagation();
        });
      });
    }

    initDropdowns() {
      return _.each($(this.DROPDOWN_SELECTOR), (dropdown) => {
        var $modal, config, dropdownParent, placeholderOption;
        config = {};
        if (!$(dropdown).hasClass("select2-hidden-accessible")) { // Only apply select2 if it hasn't already been initialized
          config.theme = "default";
          if (!this.hasOptions(dropdown) || $(dropdown).data("noselect2")) {
            return false;
          }
          if (this.hasPlaceholder(dropdown)) {
            config.placeholder = $(dropdown).attr("placeholder");
          }
          if (dropdown.hasAttribute("multiple")) {
            config.theme += " select2-container--multiple";
            config.multiple = true;
          } else {
            if (this.hasTypeahead(dropdown) && this.hasPlaceholder(dropdown)) { // Must have placeholder to clear without error
              config.theme += " select2-container--with-typeahead";
              if (this.hasEmptyOption(dropdown)) { // There has to be an empty option in order to clear
                config.allowClear = true;
              }
            } else {
              config.minimumResultsForSearch = 2e308;
            }
          }
          if (this.hasClear(dropdown)) {
            if (this.hasEmptyOption(dropdown)) { // There has to be an empty option in order to clear
              config.allowClear = true;
            }
            if (this.forceNoTypeahead(dropdown)) { //allowClear shows the search box by default, data-forcenotypeahead to hide
              config.minimumResultsForSearch = -1;
            }
          }
          if (this.hasGroups(dropdown)) {
            config.theme += " select2-container--grouped";
          }
          if (this.insideModal(dropdown)) {
            config.theme += " select2-container--modal";
            $modal = $(dropdown).parents(".modal");
            // Attach to modal, otherwise typeahead can't focus when inside a modal with tabindex -1
            if ($modal.attr('id')) {
              config.dropdownParent = $('#' + $modal.attr('id') + " .modal-content");
            }
          }
          if (dropdownParent = $(dropdown).data("dropdownParent")) {
            config.dropdownParent = $(dropdownParent);
          }
          $(dropdown).select2(config);
          if (this.hasAriaLabel(dropdown)) {
            $(dropdown).data().select2.$container.find('.select2-selection').attr('aria-labelledby', null);
            $(dropdown).data().select2.$container.find('.select2-selection').attr('aria-label', this.hasAriaLabel(dropdown));
          }
          if (this.hasRequired(dropdown)) {
            $(dropdown).data().select2.$container.find('.select2-selection').attr('aria-required', true);
          }
          $(dropdown).on('select2:opening', function(evt) {
            var $dropdown;
            $dropdown = $(evt.target).data('select2').dropdown;
            // Improving accessibility for the clear buttons
            $dropdown.$container.find('.select2-selection__clear').attr('aria-label', 'Clear').attr('role', 'button');
            if (!this.hasAttribute("multiple")) {
              if ($dropdown.$searchContainer.data('unselecting')) {
                return false;
              }
            }
          // When dropdown does not have the typeahead, if the user types when a dropdon is open, show the typeahead and give it focus (recreating the default select box type to highlight behavior)
          }).on('select2:open', function(evt) {
            var context;
            context = $(evt.target).data('select2');
            if (evt.currentTarget.dataset.searchShow === "true") {
              context.dropdown.$searchContainer.addClass('select2-search--force-show');
              context.dropdown.$search.focus();
            }
            $(document).on('keydown.select2', function(e) {
              /* Safari does not have key */
              var key, search, target;
              target = context.$container;
              if (!target.length || target.hasClass('select2-container--with-typeahead') || !target.prev('select:enabled')) {
                return;
              }
              dropdown = context.dropdown;
              search = dropdown.$search;
              key = e.key ? e.key : String.fromCharCode(e.which);
              if (key.length === 1) {
                // When user types a character we give the search box focus and show it
                dropdown.$searchContainer.addClass('select2-search--force-show');
                return search.focus();
              } else {

              }
            });
          // Preventing the dropdown from reopening when clearing
          }).on('select2:clear', function(evt) {
            return $(evt.target).data('select2').dropdown.$searchContainer.data('unselecting', true);
          // Best current way to select on tab is to hijack the close event and use a keydown
          }).on('select2:close', function(evt) {
            var $container, context, currentVal;
            context = $(evt.target);
            $container = context.data('select2').dropdown.$searchContainer;
            currentVal = context.data('select2').$element.val();
            // Remove the force-show class on the search container (added in the open event)
            if (!$container) {
              return;
            }
            $container.removeClass('select2-search--force-show');
            $(document).on('keydown.select2', function(e) {
              var highlighted, id;
              if (e.which === 9) {
                // tab
                highlighted = context.data('select2').$dropdown.find('.select2-results__option--highlighted');
                if (highlighted && highlighted.data('select2-id')) {
                  id = highlighted.data('select2-id').toString().indexOf("-") > -1 ? highlighted.data('select2-id').split('-').pop() : void 0;
                  // Do not trigger change event if the hovered option is the same as the selection or the placeholder option
                  if (id.trim() !== currentVal.trim()) {
                    context.val(id).trigger('change');
                  }
                }
              }
            });
            // Unbind the event again to avoid binding multiple times + reset unselecting attribute
            setTimeout((function() {
              $container.data('unselecting', null);
              $(document).off('keydown.select2');
            }), 1);
          });
          // Adding a visible empty option with the palceholder to fix tabbing on filters
          if (this.showEmptyOption(dropdown) && config.placeholder) {
            placeholderOption = '<option value=" ">' + config.placeholder + '</option>';
            return $(dropdown).prepend(placeholderOption).trigger('change');
          }
        }
      });
    }

    static destroySelect2(dropdown) {
      if ($(dropdown).hasClass("select2-hidden-accessible")) {
        return $(dropdown).select2('destroy');
      }
    }

    optionCount(select) {
      return $(select).children("option").length;
    }

    hasEmptyOption(select) {
      return $(select).find("option[value='']").length;
    }

    hasPlaceholder(select) {
      return select.hasAttribute("placeholder");
    }

    hasRequired(select) {
      return select.hasAttribute("aria-required") || $(select).hasClass("required");
    }

    hasAriaLabel(select) {
      return $(select).attr("aria-label");
    }

    hasOptions(select) {
      return this.optionCount(select) > 0;
    }

    hasTypeahead(select) {
      return (this.optionCount(select) > this.TYPEAHEAD_THRESHOLD && !this.forceNoTypeahead(select)) || this.forceTypeahead(select);
    }

    hasClear(select) {
      return $(select).data("allowclear");
    }

    hasGroups(select) {
      return $(select).data("hasgroups");
    }

    showEmptyOption(select) {
      return $(select).data("showemptyoption");
    }

    insideModal(select) {
      return $(select).parents(".modal-body").length;
    }

    forceTypeahead(select) {
      return $(select).data("forcetypeahead");
    }

    forceNoTypeahead(select) {
      return $(select).data("forcenotypeahead");
    }

  };

  Dropdown.prototype.DROPDOWN_SELECTOR = ".styleguide select:not([data-controller='select2'])";

  Dropdown.prototype.TYPEAHEAD_THRESHOLD = 7;

  return Dropdown;

}).call(this);

module.exports.Dropdown = Dropdown;
