import { Controller } from "@hotwired/stimulus"

export function debounce(fn, delay = 10) {
  let timeoutId = null

  return (...args) => {
    const callback = () => fn.apply(this, args)
    clearTimeout(timeoutId)
    timeoutId = setTimeout(callback, delay)
  }
}

// This is only used during tests. It sets some data-* attribute flags in <body>
// to capture when Hotwire components are initiated. It's meant to offer a mechanism to wait
// for those to Capybara.
// Adapted from hey.com
export default class extends Controller {
  connect() {
    this.stimulusIsReady()
    this.monitorLoadOfJavascriptFiles()
    // TODO: Re-visit this once the live dashboard branch is merged
    // this.monitorActionCableReadiness()
  }

  // private

  stimulusIsReady() {
    this.element.setAttribute("data-stimulus-ready", true)
  }

  monitorLoadOfJavascriptFiles = debounce(() =>{
    const scripts = window.performance.getEntriesByType("resource").filter(resource => resource.name.endsWith(".js"))
    if (scripts.every(script => script.responseEnd)) {
      this.javascriptFilesWereLoaded()
    } else {
      this.monitorLoadOfJavascriptFiles()
    }
  }, 500)

  async javascriptFilesWereLoaded() {
    this.element.setAttribute("data-javascript-files-loaded", true)
  }
}
