import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'reasonObjValue',
    'reasonDetails',
    'reasonDetailsError'
  ]

  connect() {}

  checkFormErrors(event) {
    if(this.reasonObjValueTarget.value == 'Other' && this.reasonDetailsTarget.value == '') {
      event.preventDefault();
      this.reasonDetailsErrorTarget.classList.remove('d-none')
    }
  }
}
