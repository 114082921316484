import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  copy() {
    let url = new URL(window.location.href)
    url.searchParams.set('set_account', document.body.dataset.accountId)
    
    navigator.clipboard.writeText(url.toString())
  }
}