import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.warningsStatus = {} 
    this.setControllerReference()
    this.cleanerEarlyWarnings()
  }

  setControllerReference() {
    $(document).on('patient_agreement.state', function(event, callback) {
      callback(this)
    }.bind(this))
  }

  cleanerEarlyWarnings() {
    document.addEventListener('turbo:submit-end', (event) => { 
      const requestMethod = event.target.getAttribute('method')

      if (requestMethod == 'delete') {
        const turboFrameName = event.target.getAttribute('data-turbo-frame')
        const warningType = document.querySelector(`#${turboFrameName} .early-warnings`)
                                      .getAttribute('data-ew-popover-warning-type-value')

        this.warningsStatus[warningType] = {}
      }
    });
  }
}
