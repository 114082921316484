import { get } from '@rails/request.js'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {}

  print(event) {
    let url = event.target.href.replace('.pdf', '');

    get(url, { responseKind: "turbo-stream" })
  }
}