import { get } from '@rails/request.js'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  async paymentTypeChange(event) {
    let data = {"payment_method[payment_type]": event.target.value}
    let url = event.target.dataset.url
    const ajaxCall = await get(url, {
      query: new URLSearchParams(data),
      responseKind: "turbo-stream"
    })
  }
}

