import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'scanBtn',
    'scanModal',
    'barcodePickerDiv'
  ];

  connect() {
    this.configureScandit();

    $("body").off("hide.bs.modal").on("hide.bs.modal", "#scandit-modal", () => {
      this.handleModalHide()
    })

    $("body").on("shown.bs.modal", "#scandit-modal", () => {
      this.handleModalShown()
    })
  }

  handleModalHide() {
    if (this.barcodePicker && this.barcodePicker !== "initializing") {
      this.barcodePicker.destroy();
      this.barcodePicker = null;
    }
  }

  handleModalShown() {
    if (window.ScanditSDK) {
      if (!this.barcodePicker) {
        this.configureBarcodePicker();
      }
    }
  }

  configureScandit() {
    const licenseKey = document.getElementById("scandit-license-key").dataset.key;

    if (!licenseKey) {
      throw "Scandit license key missing from #scandit-license-key";
    }

    try {
      this.ScanditSDK = ScanditSDK.configure(licenseKey, {
        engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",
      })
        .catch((error) => this.handleConfigureError(error))
        .then(() => {
          this.scanBtnTarget.classList.remove("disabled");
          this.scanBtnTarget.removeAttribute("disabled");
        });
    } catch (e) {
      this.handleConfigureError(e);
    }
  }

  handleConfigureError(error) {
    const tooltipContainer = this.scanBtnTarget.closest(".d-inline-block");

    tooltipContainer.setAttribute('title', '<h5>Browser Not Supported</h5><span>Please use the latest version of Chrome, Safari, or Edge.</span>');
    tooltipContainer.tooltip('dispose');
    tooltipContainer.tooltip({ html: true });

    this.scanBtnTarget.classList.add("disabled");
    this.scanBtnTarget.setAttribute("disabled", true);

    console.log("Unable to load Scandit Library");
    console.log(error);
  }

  configureBarcodePicker() {
    this.barcodePicker = "initializing";
    this.barcodePickerDivTarget.innerHTML = "";

    this.ScanditSDK
      .then(ScanditSDK.CameraAccess.getCameras)
      .then(() => {
        const scanSettings = new ScanditSDK.ScanSettings({
          enabledSymbologies: ["code128", "code39", "databar", "databar-expanded", "databar-limited", "ean13", "two-digit-add-on", "five-digit-add-on", "ean8", "upca", "upce"],
          searchArea: { x: 0, y: 0.5, width: 1.0, height: 0.01 },
        });

        scanSettings.getSymbologySettings(ScanditSDK.Barcode.Symbology.UPCA).enableExtensions(ScanditSDK.SymbologySettings.Extension.REMOVE_LEADING_ZERO);

        return ScanditSDK.BarcodePicker.create(this.barcodePickerDivTarget, {
          scanSettings: scanSettings,
        });
      })
      .then((barcodePicker) => {
        this.barcodePicker = barcodePicker;

        if (!document.getElementById("scandit-modal").classList.contains("show")) {
          // Force the barcode picker to be destroyed if the modal was closed before this promise resolved
          document.getElementById("scandit-modal").dispatchEvent(new Event("hide.bs.modal"));
        } else {
          this.barcodePicker.on("scan", (scanResult) => {
            if (scanResult.barcodes && scanResult.barcodes.length) {
              document.getElementById("product-criteria").value = scanResult.barcodes[0].data;
              $("#scandit-modal").modal("hide");
              $("#product-search-form input[type=submit]").trigger("click");
            }
          });
        }
      })
      .catch((error) => {
        const modal = document.getElementById("scandit-modal");
        modal.querySelector(".modal-body-error").classList.remove("d-none");
        this.handleConfigureError(error);
      });
  }
}
