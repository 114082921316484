const emptyCallback = () => {};

$(() => {
  const $signatureWrapper = $('#signature-modal-wrapper');
  const $canvas = $('#signature-canvas');
  if (!$signatureWrapper[0])
    return;
  const $en = $('.lang-en');
  const $es = $('.lang-es');

  const controller = new Momd.SignatureController($canvas);
  controller.onChange(() => enableSave(true));
  const $signatureCanvasContainer = $signatureWrapper.find('.signature-canvas-container');
  const $clearSignature = $signatureWrapper.find('.clear-signature');
  const $saveSignature = $signatureWrapper.find('.save-signature');
  const $cancelSignature = $signatureWrapper.find('.cancel-signature');
  let success;
  let cancel;
  let formData;

  window.openSignaturePad = async (opts = {}) => {
    $signatureWrapper.find('.signature-text').text(opts.signatureText || '');
    success = opts.success || emptyCallback;
    cancel = opts.cancel || emptyCallback;
    formData = opts.formData || {};
    if (opts.lang === 'es') {
      $en.hide();
      $es.show();
    } else {
      $en.show();
      $es.hide();
    }
    enableSave(false);
    await showModal(true);
  }

  async function showModal(show) {
    $signatureWrapper.css('display', show ? 'block' : 'none');
    if (show) {
      await controller.clearSignature();
      await controller.resizeCanvas($signatureCanvasContainer.width(), $signatureCanvasContainer.height());
      await controller.setup();
      await controller.initSigWeb();
    } else {
      await controller.teardown();
    }
  }

  async function doCancel() {
    $signatureWrapper.hide();
    cancel();
    await showModal(false);
  }

  async function doSave() {
    enableSave(false, true);
    const response = success(controller.getSignature());
    if (response instanceof Promise)
      await response;
    await showModal(false);
  }

  function enableSave(enable, saving = false) {
    enable ? $saveSignature.prop('disabled', false) : $saveSignature.prop('disabled', true);
    $saveSignature.innerText = !enable && saving ? 'Saving...' : 'Save Signature';
  }

  $clearSignature.on('click', async e => {
    e.preventDefault();
    enableSave(false);
    await controller.clearSignature();
  });

  $cancelSignature.on('click', doCancel);
  $saveSignature.on('click', doSave);
});
