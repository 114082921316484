import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {}

  uploadFiles(event) {
    event.detail.formSubmission.stop();

    const formData = new FormData(event.currentTarget)
    this.progressBar = event.currentTarget.querySelector('.progress-bar')

    this.buildXHR();
    this.listenToUploadProgress();
    this.showProgressBar();
    this.xhr.send(formData);
  }

  buildXHR() {
    this.xhr = new XMLHttpRequest()
    this.xhr.open('POST', this.element.action);
    this.xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    this.xhr.setRequestHeader('Accept', 'text/vnd.turbo-stream.html');
    this.xhr.onreadystatechange = () => { if (this.xhr.readyState === XMLHttpRequest.DONE) Turbo.renderStreamMessage(this.xhr.responseText) };
  }

  listenToUploadProgress() {
    this.xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        let progressValue = (event.loaded / event.total) * 100;
        let progressValueText = `${Math.round(progressValue)}%`;

        this.updateProgressBar(progressValue, progressValueText);
      }
    }
  }

  showProgressBar() {
    this.progressBar.parentElement.classList.remove('d-none');
  }

  updateProgressBar(progressValue, progressValueText) {
    this.progressBar.style.width = `${progressValue}%`;
    this.progressBar.setAttribute('aria-valuenow', progressValue);
    this.progressBar.textContent = progressValueText;
  }
}
