import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'selectLengthOfNeed',
    'lengthOfNeed',
    'injuryContainer',
    'selectDateOfInjury',
    'selectInjuryType',
    'selectInjuryState'
  ]

  connect() {
    this.updateLON(false)
    if (this.hasSelectInjuryTypeTarget) {
      this.updateDateOfInjury()
      this.updateInjuryState()
    }
  }

  updateLON(clean = true) {
    if(this.selectLengthOfNeedTarget.value == 'custom') {
      this.lengthOfNeedTarget.hidden = false
      if(clean) { this.lengthOfNeedTarget.value = '' }
    } else {
      this.lengthOfNeedTarget.hidden = true
      this.lengthOfNeedTarget.value = this.selectLengthOfNeedTarget.value
    }
  }

  updateDateOfInjury() {
    if(this.selectDateOfInjuryTargets[0].value != '' && this.selectDateOfInjuryTargets[1].value != '' && this.selectDateOfInjuryTargets[2].value != '') {
      this.selectInjuryTypeTarget.parentElement.hidden = false
      this.updateInjuryState()
      this.injuryContainerTarget.classList.add('d-flex')
      this.injuryContainerTarget.classList.remove('d-none')
    } else {
      this.selectInjuryTypeTarget.parentElement.hidden = true
      this.selectInjuryStateTarget.hidden = true
      this.injuryContainerTarget.classList.add('d-none')
      this.injuryContainerTarget.classList.remove('d-flex')
    }
  }

  updateInjuryState() {
    if(this.selectInjuryTypeTarget.value == 'auto_accident') {
      this.selectInjuryStateTarget.hidden = false
    } else {
      this.selectInjuryStateTarget.hidden = true
    }
  }
}
