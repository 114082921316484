namespace('Momd', function(exports) {
  function getTouchEvent(e) {
    const touch = e.originalEvent?.targetTouches && e.originalEvent.targetTouches[0];
    return touch || e;
  }

  // Due to the sigweb integration, it's important to only have one instance of this running at a time.
  exports.SignatureController = class SignatureController {
    _drawing = false;
    _coords;
    _onChange;
    _resizeObserver;
    _sigWebFromBottom;
    _resizeDebounce;

    constructor($canvas) {
      this._$canvas = $canvas;
      this._canvas = $canvas[0];
      this._context = this._canvas.getContext('2d');
    }

    _signStart = (e) => {
      e = getTouchEvent(e);
      this._coords = this._canvas.getBoundingClientRect();
      this._context.beginPath();
      this._context.moveTo(e.clientX - this._coords.left, e.clientY - this._coords.top);
      this._drawing = true;
    }

    _signMove = (e) => {
      if (!this._drawing) return;
      e.stopPropagation();
      e.preventDefault();
      e = getTouchEvent(e);
      this._context.lineWidth = 3;
      this._context.lineTo(e.clientX - this._coords.left, e.clientY - this._coords.top);
      this._context.stroke();
      this._onChange && this._onChange(e);
    }

    _signEnd = () => {
      this._context.closePath();
      this._drawing = false;
    }

    _onCanvasResize = () => {
      const canvasDims = { width: this._canvas.offsetWidth, height: this._canvas.offsetHeight };
      if (canvasDims.width === this._canvas.width && canvasDims.height === this._canvas.height)
        return;
      clearTimeout(this._resizeDebounce);
      this._resizeDebounce = setTimeout(async () => {
        const dims = this._calcSigWebDimensions();
        this.resizeCanvas(canvasDims.width, canvasDims.height);
        await Momd.Topaz.resizeImage(canvasDims.width, dims.height, 0, dims.fromBottom);
      }, 500);
    }

    _calcSigWebDimensions = () => {
      const sigHeight = this.sigWebHeight();
      const fromBottom = this._sigWebFromBottom || Math.floor(this._canvas.height * 0.8) - sigHeight;
      return { fromBottom, height: sigHeight };
    }

    _resizedCanvas(width, height) {
      const resizedCanvas = document.createElement('canvas');
      const resizedContext = resizedCanvas.getContext('2d');
      resizedCanvas.width = width;
      resizedCanvas.height = height;
      resizedContext.drawImage(this._canvas, 0, 0, width, height);
      return resizedCanvas;
    }

    setup = () => {
      if (!this._$canvas.length) {
        throw 'Canvas does not exist';
      }
      this._$canvas.on('mousedown touchstart', this._signStart);
      this._$canvas.on('mousemove touchmove', this._signMove);
      this._$canvas.on('mouseup mouseout touchend', this._signEnd);
      this._resizeObserver = new ResizeObserver(this._onCanvasResize);
      this._resizeObserver.observe(this._canvas);
    }

    teardown = async () => {
      this._$canvas.off('mousedown touchstart', this._signStart);
      this._$canvas.off('mousemove touchmove', this._signMove);
      this._$canvas.off('mouseup mouseout touchend', this._signEnd);
      this._resizeObserver?.unobserve(this._canvas);
      await Momd.Topaz.initializeCanvas(null);
    }

    getSignature = () => {
      const resizedCanvas = this._resizedCanvas(540, 300);
      return resizedCanvas.toDataURL('image/png', 0);
    }

    clearSignature = async () => {
      await Momd.Topaz.clearSignature();
      this._context.clearRect(0, 0, this._canvas.width, this._canvas.height);
    }

    onChange = (callback) => {
      this._onChange = callback;
      Momd.Topaz.onchange = callback;
    }

    resizeCanvas = (width, height) => {
      const resizedCanvas = this._resizedCanvas(width, height);
      this._canvas.height = height;
      this._canvas.width = width;
      this._context.drawImage(resizedCanvas, 0, 0);
    }

    sigWebHeight = () => {
      return Math.floor(this._canvas.width / 5);
    }

    initSigWeb = async (fromBottom = null) => {
      this._sigWebFromBottom = fromBottom;
      const dims = this._calcSigWebDimensions();
      await Momd.Topaz.initializeCanvas(this._canvas, this._canvas.width, dims.height, 0, dims.fromBottom);
    }
  }
});
