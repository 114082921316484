import { post } from '@rails/request.js'
import { Controller } from "@hotwired/stimulus"

class HealthIpassController extends Controller {
  static targets = ['form', 'nonce']

  static values = {
    paymentMethodId: Number
  }

  connect() {
    let container = "#payment-methods-container";
    this.credentials = {};
    this.creditCard = {};
    this.currentFormEl = {};
  }

  _getCardData() {
    this.creditCard.cardNumber = $('#card_number').val();
    this.creditCard.month = $('#expiry_month').val();
    this.creditCard.year = $('#expiry_year').val();
    this.creditCard.cardCode = $('#cvv').val();
    this.creditCard.amount = $("#amount").val();
    this.creditCard.cardHolderFirstName = $('#card_holder_first_name').val();
    return this.creditCard.cardHolderLastName = $('#card_holder_last_name').val();
  }

  _getCredentials() {
    this.credentials.clientKey = $("#charge-form").data("client-key");
    return this.credentials.apiLoginID = $("#charge-form").data("api-login-id");
  }

  _validateAmountAndCardHolderName() {
    if (parseFloat($("#amount").val()) === 0) {
      return "Charge amount must exceed $0.00.";
    } else if  ($("#card_holder_first_name").val().length === 0) {
      return "Card holder first name required.";
    } else if  ($("#card_holder_last_name").val().length === 0) {
      return "Card holder last name required.";
    } else {
      return "";
    }
  }

  async submitRecordCharge(event) {
    event.preventDefault();
    this.creditCard.amount = $("#amount").val()
    this.currentFormEl = event.currentTarget;
    const url = '/admin/patient_agreements/' + this.getPatientAgreementId(this.currentFormEl) + "/charges";
    const payment_method_id = this.paymentMethodIdValue;
    const charge = $.param({
      charge: {
        external_payment: false,
        charge_type: 'health_ipass',
        nonce: "",
        amount: this.creditCard.amount,
        payment_method_id,
        card_holder_first_name: "",
        card_holder_last_name: "" ,
        endpoint: $(event.target).data('endpoint')
      }
    });
    const card = $("#charge-form .permitted").serialize();
    const data = card  + '&' + charge;

    const ajaxCall = await post(url, {
      query: new URLSearchParams(data),
      responseKind: "turbo-stream"
    })
  }


  submitPayment(event) {
    event.preventDefault();
    $(".payment-method-edit-button").addClass("disabled");
    $(".payment-remove-button").addClass("disabled");
    this.currentFormEl = event.currentTarget;
    this._getCardData();
    this._getCredentials();

    const secureData = {};
    const validations = this._validateAmountAndCardHolderName();

    if (validations.length === 0) {
      $("#submit-charge").attr("disabled", true);
      $("#charge-errors").addClass("d-none");

      secureData.cardData = this.creditCard;
      secureData.authData = this.credentials;
      return Accept.dispatchData(secureData, 'momdAuthorizeNetResponseHandler');
    } else {
      $("#submit-charge").attr("disabled", false);
      $("#charge-errors").text(validations);
      return $("#charge-errors").removeClass("d-none");
    }
  }

  getPatientAgreementId(currentFormEl) {
    return $(".page-header").data('id');
  }

  async sendAuthNetRequest(event, response) {
    event.preventDefault();

    const url = "/admin/patient_agreements/" + this.getPatientAgreementId(this.currentFormEl) + "/charges";
    const payment_method_id = this.paymentMethodIdValue;
    const charge = $.param({
      charge: { 
        external_payment: false,
        charge_type: 'auth_net',
        nonce: response.dataValue, 
        amount: this.creditCard.amount, 
        payment_method_id, 
        card_holder_first_name: this.creditCard.cardHolderFirstName,
        card_holder_last_name: this.creditCard.cardHolderLastName
      }
    });
    const card = $("#charge-form .permitted").serialize();
    const data = card  + '&' + charge

    const ajaxCall = await post(url, {
      query: new URLSearchParams(data),
      responseKind: "turbo-stream"
    })
    if (ajaxCall.ok) {
      this.onSuccess()
    }
  }

  onSuccess(data, status, xhr) {
    $(".payment-method-edit-button").removeClass("disabled");
    return $(".payment-remove-button").removeClass("disabled");
  }

  static responseHandler(response) {
    if (response.messages.resultCode === 'Error') {
      let errors = "";

      for (let message of Array.from(response.messages.message)) {
        errors += message.text;
      }

      $("#charge-errors").text(errors);
      $("#charge-errors").removeClass("d-none");
      return $("#submit-charge").attr("disabled", false);
    } else {
      $("#charge-errors").addClass("d-none");
      return $.event.trigger("nonce:success", response.opaqueData);
    }
  }
}

export default HealthIpassController
