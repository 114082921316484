import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if ($('#patient_agreements.show').hasClass('show-sidebar')) {
      this.loadSidebarState()
    }
  }

  expand(event) {
    const $paCard = $(event.target).closest('.pa-card')
    $paCard.find('.pa-card__expanded').toggleClass('hidden d-none')
    $paCard.find('.control__expand').toggleClass('hidden d-none')
    $paCard.find('.control__collapse').toggleClass('hidden d-none').trigger('focus')
    $('.tooltip').tooltip('hide')
  }

  collapse(event) {
    const $paCard = $(event.target).closest('.pa-card')
    $paCard.find('.pa-card__expanded').toggleClass('hidden d-none')
    $paCard.find('.control__expand').toggleClass('hidden d-none').trigger('focus')
    $paCard.find('.control__collapse').toggleClass('hidden d-none')
    $('.tooltip').tooltip('hide')
  }

  expandSidebar(event) {
    $(event.target).addClass('d-none')
    $(event.target).siblings().removeClass('d-none')
    $('body').removeClass('show-sidebar--collapsed')
    $('.tooltip').tooltip('hide')
    $('.pa-sidebar__section-links .btn').tooltip('dispose')
    $('.pa-sidebar__section-links .btn').tooltip({ placement: 'top' })
    this.saveSidebarState('expanded')
  }

  collapseSidebar(event) {
    $(event.target).addClass('d-none')
    $(event.target).siblings().removeClass('d-none')
    $('body').addClass('show-sidebar--collapsed')
    $('.tooltip').tooltip('hide')
    $('.pa-sidebar__section-links .btn').tooltip('dispose')
    $('.pa-sidebar__section-links .btn').tooltip({ placement: 'right' })
    this.saveSidebarState('collapsed')
  }

  loadSidebarState() {
    const sidebarState = this.getCookie("sidebarState");
    if (sidebarState === "collapsed") {
      $('body').addClass('show-sidebar--collapsed')
      $('.sidebar-collapse').addClass('d-none')
      $('.sidebar-expand').removeClass('d-none')
      $('.pa-sidebar__section-links .btn').tooltip('dispose')
      $('.pa-sidebar__section-links .btn').tooltip({ placement: 'right' })
    }
  }

  saveSidebarState(sidebarState) {
    this.setCookie("sidebarState", sidebarState)
  }

  // Utility functions for setting and getting cookies
  setCookie(name, value, days = 365) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/`;
  }

  getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}
