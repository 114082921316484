import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'veriproForm',
    'disableVeriproActions',
    'setVeriproResponseToProcessing',
    'subscribeToVeriproChannel',
    'payerSelect',
    'veriproSubmitButton',
    'sectionFooter'
  ]

  static values = {
    paymentMethodId: Number,
    patientAgreementId: Number
  }

  connect() {
    if(this.sameOrSimilarNeedsRecheck()) {
      this.checkSameOrSimilar()
    }
  }

  sameOrSimilarNeedsRecheck() {
    return $(this.element).find(".needs-recheck").length > 0
  }

  veriproSubmitButtonTargetConnected() {
    this.updateVeriproSubmitButton()
  }

  sectionFooterTargetConnected(element) {
    $("[rel=tooltip]").tooltip()
    $(".payer-select").each((_, el) => {
      $(el).val($(el).data("payerId")).trigger("change")
    })
    $(element).on('select2:select', this.enableEligibilityButtons)
  }

  enableEligibilityButtons(event) {
    $(".select2-selection__rendered").each((_, el) => {
      let $button = $(el).closest(".payer-lookup-form").find(".veripro-submit.disabled")
      if($(el).text() != "") {
        $button.attr("disabled", false)
        $button.removeClass("disabled")
      }
    })
  }

  submitVeriproForm() {
    this.veriproFormTarget.submit()
  }

  disableVeriproActionsTargetConnected() {
    let isFirstRequest = $(this.element).find(".self-pay.new_charge").length == 0

    $(this.element).find(".self-pay.new_charge").each((_, el) => {
      if(!isFirstRequest) {
        $(el).attr("title", "Processing eligibility request") 
      }
      $(el).tooltip()
      if(!$(el).hasClass("button-disabled")) {
        $(el).attr("disabled", true) 
      }
      if(!isFirstRequest) {
        $(el).addClass("loading")
      }
    })

    this.veriproSubmitButtonTargets.forEach((_, el) => {
      $(el).attr("disabled", true)
      $(el).addClass("disabled")
      $(el).find('.veripro-button-text').removeClass('d-none')
      $(el).find('.veripro-button-loading').addClass('d-none')
    })
  }

  setVeriproResponseToProcessingTargetConnected() {
    let $parent = $(this.element)
    let $viewCoverageWrapper = $parent.find('.view-coverage-wrapper')
    let $viewCoverage = $parent.find('.payer-view-coverage')
    $viewCoverage.addClass("disabled").attr("aria-disabled", true)
    $viewCoverageWrapper.addClass("disabled")
  }

  subscribeToVeriproChannelTargetConnected(target) {
    let $parent = $(this.element)
    let pusher = window.getPusher()
    let patientAgreementId = this.patientAgreementIdValue
    let paymentMethodId = this.paymentMethodIdValue
    let channel = pusher.subscribe('private-patient-agreement-'+patientAgreementId+'-channel')
    let $viewCoverageWrapper = $parent.find('.view-coverage-wrapper')
    let $viewCoverage = $parent.find('.payer-view-coverage')
    let callback = () => {
      $viewCoverage.removeClass("disabled").attr("aria-disabled", false)
      $viewCoverageWrapper.removeClass("disabled")
    }

    let channelCallback = (data) => {
      if(data.patient_agreement_id.toString() == patientAgreementId && data.payment_method_id.toString() == paymentMethodId) {
        callback()
        pusher.unsubscribe('private-patient-agreement-'+patientAgreementId+'-channel')
      }
    }

    let timeout = window.setTimeout(callback, 5000)
    channel.bind('coverage-complete', channelCallback)
  }

  showOpenModalButtons() {
  }

  checkSameOrSimilar() {
    const url = `/admin/patient_agreements/${this.patientAgreementIdValue}/same_or_similar_requests`;

    post(url, {
      responseKind: 'turbo-stream',
      query: new URLSearchParams({ payment_method_id: this.paymentMethodIdValue, patient_agreement_id: this.patientAgreementIdValue })
    })
  }

  updateVeriproSubmitButton() {
    if(!this.hasVeriproSubmitButtonTarget) { return; }
    if (this.veriproSubmitButtonTarget.dataset.hasProduct === 'true') {
      if (this.veriproSubmitButtonTarget.dataset.payAtTimeOfService === 'true') {
        this.veriproSubmitButtonTarget.title = 'Products that are Time of Service/Self Pay cannot be verified with VeriPro.'
        this.veriproSubmitButtonTarget.setAttribute("disabled", true)
      } else {
        if (this.payerSelectTarget.value == "") {
          this.veriproSubmitButtonTarget.setAttribute("disabled", true)
        } else {
          this.veriproSubmitButtonTarget.removeAttribute("disabled")
        }
      }
    } else {
      this.veriproSubmitButtonTarget.title = 'A product must be added to the PA to enable VeriPro'
      this.veriproSubmitButtonTarget.setAttribute("disabled", true)
    }
  }
}
