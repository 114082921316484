import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  open(event) {
    const $target = $(event.target)
    const modalId = $target.data("modal")

    const $targetDisabled = $target.data('disabled') ||
                            $target.hasClass('button-disabled') ||
                            $target.hasClass('disabled')

    if ($targetDisabled) {
      event.preventDefault()
      return false
    }

    if(!!modalId) {
      $(modalId).modal("show")
    } else {
      BigSpinner.finishedTimer = setTimeout(BigSpinner.finished, 3000)
      BigSpinner.add()
    }
  }
}
