import { post } from '@rails/request.js'
import { Controller } from "@hotwired/stimulus"

window.CustomForm = {
  pspdfkitInstance: null,
  updateCustomFormPath: null,
  customFormPath: null
};

const cancelPspdfkit = () => {
  return $("#pspdfkit-modal").modal('hide');
};

const highlightRequiredFields = () => {
  const missingRequiredFields = formFields => {
    let fields = [];
    for (let formField of Array.from(formFields.toArray())) {
      if (formField.required && !(formField.value)) {
        fields = fields.concat(formField);
      }
    }
    return fields;
  };

  return CustomForm.pspdfkitInstance.getFormFields().then(formFields => {
    const fieldsToHighlight = missingRequiredFields(formFields);
    if (fieldsToHighlight.length > 0) {
      const els = $("iframe")[0].contentDocument.getElementsByClassName("PSPDFKit-Annotation-Widget-Required");
      return (() => {
        const result = [];
        for (let el of Array.from(els)) {
          if (el.value === "") {
            const style = el.getAttribute("style");
            result.push(el.setAttribute("style",style+";border: 2px solid red!important;"));
          } else {
            result.push(el.setAttribute("style",""));
          }
        }
        return result;
      })();
    }
  });
};

const toolbarItems = () => {
  const itemsToRemove = [
    "annotate",
    "arrow",
    "debug",
    "document-editor",
    "ellipse",
    "highlighter",
    "image",
    "ink",
    "ink-eraser",
    "ink-signature",
    "line",
    "note",
    "polygon",
    "polyline",
    "rectangle",
    "search",
    "stamp",
    "text",
    "text-highlighter"
  ];

  return _.reject(PSPDFKit.defaultToolbarItems, item => _.includes(itemsToRemove, item.type));
};

CustomForm.openPspdfkitWindow = function() {
  $('#pspdfkit-modal').modal('show');

  CustomForm.pspdfkitInstance = null;

  PSPDFKit.Options.SIGNATURE_SAVE_MODE = PSPDFKit.SignatureSaveMode.NEVER;

  return PSPDFKit.load({
    container: "#pspdfkit",
    pdf: CustomForm.customFormPath,
    editableAnnotationTypes: [PSPDFKit.Annotations.WidgetAnnotation],
    toolbarItems: toolbarItems(),
    licenseKey: $('#patient_agreements').data('pspdfkit-license-key')
  }).then(function(instance) {
    CustomForm.pspdfkitInstance = instance;
    instance.addEventListener("viewState.change", highlightRequiredFields);
    const state = instance.viewState;
    let newState = state.set("viewportPadding", { horizontal: 0, vertical: 0 });
    newState = state.set("zoom", PSPDFKit.ZoomMode.FIT_TO_WIDTH);
    instance.setViewState(newState);

    const formFields = instance.getFormFieldValues();
    fetch(`/admin/patient_agreements/${patientAgreementID}/custom_form_params`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.text();
  }).then(data => {
      const list = JSON.parse(data);

      return (() => {
        const result = [];
        for (let name in formFields) {
          const value = formFields[name];
          if ((typeof value === "string") && ((value.trim().length === 0) || (value === "Off"))) {
            result.push(CustomForm.pspdfkitInstance.setFormFieldValues({ [name]: list[name]}));
          } else if (value && value.includes('Off')) {
            result.push(CustomForm.pspdfkitInstance.setFormFieldValues({ [name]: [list[name]] }));
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    });
    return null;
  });
};

class PatientAgreementCustomFormsController extends Controller {
  connect() {
    $(this.element).on("hidden.bs.modal", "#pspdfkit-modal", () => {
      console.log('unloading')
      this.unloadPspdfkit();
    })
  }
  selectCustomForm() {
    $('#attachments-select-custom-form-modal').modal('hide');
    CustomForm.customFormPath = $(event.currentTarget).data("openCustomFormPath");
    CustomForm.updateCustomFormPath = $(event.currentTarget).data("saveCustomFormPath");
    return CustomForm.openPspdfkitWindow();
  }

  editCustomForm() {
    CustomForm.customFormPath = $(event.currentTarget).data("openCustomFormPath");
    CustomForm.updateCustomFormPath = $(event.currentTarget).data("saveCustomFormPath");
    return CustomForm.openPspdfkitWindow();
  }

  unloadPspdfkit() {
    PSPDFKit.unload("#pspdfkit");
  }

  downloadFromPspdfkit() {
    return CustomForm.pspdfkitInstance.exportPDF().then(async (buffer) => {
      const blob = new Blob([buffer], { type: "application/pdf" });
      const formData = new FormData()
      formData.append('attachment_type', "Other")
      formData.append('files[]', blob)

      const ajaxCall = await post(CustomForm.updateCustomFormPath, {
        body: formData,
        responseKind: "turbo-stream"
      })

    });
  }

  confirmCustomForm() {
    CustomForm.customFormPath = $(event.currentTarget).data("openCustomFormPath");
    CustomForm.updateCustomFormPath = $(event.currentTarget).data("saveCustomFormPath");

    return PSPDFKit.load({
      container: "#pspdfkit-hidden",
      pdf: CustomForm.customFormPath,
      editableAnnotationTypes: [PSPDFKit.Annotations.WidgetAnnotation],
      toolbarItems: toolbarItems(),
      licenseKey: $('#patient_agreements').data('pspdfkit-license-key')
    }).then(instance => instance.exportPDF({flatten: true}).then(async (buffer) => {
      const blob = new Blob([buffer], { type: "application/pdf" });

      const formData = new FormData();
      formData.append('attachment_type', "Other");
      formData.append('complete_and_attach', 1);
      formData.append('files[]', blob);

      const ajaxCall = await post(CustomForm.updateCustomFormPath, {
        body: formData,
        responseKind: "turbo-stream"
      })
    }));
  }
}

export default PatientAgreementCustomFormsController;
