namespace('Momd', function(exports) {
  return exports.ModalAttachmentUploader = (function() {
    class ModalAttachmentUploader extends Backbone.View {
      constructor(opts = {}) {
        super();
        this.onSuccess = opts.onSuccess;
        this.onAttach = opts.onAttach;
        if (opts.attachmentFieldSelector) {
          this.attachmentFieldSelector = opts.attachmentFieldSelector;
        }
        this.skipXhr = opts.skipXhr || false;
      }

      setupHandlers() {
        $('body').on('change', this.attachmentFieldSelector, (event) => {
          var fileInput, target;
          if ($(event.target).hasClass('disabled')) {
            event.preventDefault();
            return;
          }
          this.$attachmentDiv = $(event.target).closest('.file_attachment');
          this.restockId = $(event.target).closest('.modal').data('restockId');
          this.fileUploadPath = this.$attachmentDiv.data()["fileUploadPath"];
          this.progressBar = this.$attachmentDiv.find('.progress');
          this.spinner = this.$attachmentDiv.find('.spinner');
          this.chooseFileBtn = this.$attachmentDiv.find('.file-attachment-choose-file');
          target = event.target;
          fileInput = $(target);
          this.file = target.files[0];
          if (target.files[1] != null) {
            this.progressBar.addClass('d-none');
            this.$attachmentDiv.addClass('file_attachment--error');
            this.$attachmentDiv.find('.error-message').text('Too many files attempted. Please try again, uploading only one file at a time.');
          } else {
            if (this.skipXhr) {
              if (this.onAttach) {
                return this.onAttach(this.file);
              }
            } else {
              return this.doXhr();
            }
          }
        });
        return $('body').on('click', '.add-attachment-modal .attachment-field', function(event) {
          return $('.attachment-field').val('');
        });
      }

      doXhr() {
        var formData, xhr;
        xhr = new XMLHttpRequest();
        xhr.open('POST', this.fileUploadPath);
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        xhr.onload = (event) => {
          var response;
          response = event.currentTarget.response;
          if (event.currentTarget.status === 200) {
            $(".modal.show").modal("hide");
            this.onSuccess(event.currentTarget.status, response);
          } else {
            this.progressBar.addClass('d-none');
            this.$attachmentDiv.addClass('file_attachment--error');
            this.$attachmentDiv.find('.error-message').text('There was an error attaching the file. ' + response);
          }
          this.chooseFileBtn.removeClass('disabled');
          return this.chooseFileBtn.text('Choose File');
        };
        xhr.upload.onprogress = function(event) {
          var progressValue, progressValueText;
          if (event.lengthComputable) {
            progressValue = (event.loaded / event.total) * 100;
            progressValueText = `${Math.ceil(progressValue)}%`;
            return this.progressBar.css('width', `${progressValue}%`).attr('aria-valuenow', progressValue).text(progressValueText);
          }
        };
        xhr.upload.onload = function(event) {
          this.progressBar.addClass('d-none');
          this.$attachmentDiv.removeClass('file_attachment--error');
          return this.$attachmentDiv.find('.error-message').text('');
        };
        formData = new FormData();
        formData.append('files[]', this.file);
        formData;
        this.progressBar.removeClass('d-none');
        this.chooseFileBtn.addClass('disabled');
        this.chooseFileBtn.text('Uploading...');
        return xhr.send(formData);
      }

    };

    ModalAttachmentUploader.prototype.attachmentFieldSelector = '.add-attachment-modal .attachment-field';

    return ModalAttachmentUploader;

  }).call(this);
});
