// This class is a base class for all modal controllers and should not be used directly in the DOM.
// The buttons that open the modal should be hidden in the DOM and shown by implementing `showOpenModalButtons`

// This class is used to control the Singleton modal present in the DOM. It also provides some baseline functionality for modals.
// In general, it should not be used as a base class to extend from.
// Opening modals is handled in the `modal_links_controller.js` controller.
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["success", "content"]

  connect() {
    this.setupBootstrapListeners()
  }

  setupBootstrapListeners() {
    let controller = this

    $(this.element).on('shown.bs.modal', function(event) {
      // Dispatch the event to be able to use `data-action="shown.bs.modal->controllerName#modalShown"` in the html
      //  b4 uses JQ for event binding, so we need to use the native event API to dispatch the event
      const e = new Event('shown.bs.modal')
      this.dispatchEvent(e)
      controller.modalShown()
    })

    $(this.element).on('hidden.bs.modal', function(event) {
      const e = new Event('hidden.bs.modal')
      this.dispatchEvent(e)
      controller.modalHidden()
    })
  }

  modalShown() {
    // Implement callback in your controller if needed
  }

  modalHidden() {
    // Implement callback in your controller if needed
  }

  showModal() {
    $(this.element).modal("show")
  }

  hideModal() {
    $(this.element).modal("hide")
  }

  successTargetConnected() {
    $(this.element).modal("hide")
  }

  contentTargetConnected() {
    BigSpinner.finished()
    $(this.element).modal("show")
  }
}
