import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'searchWrapper',
    'form',
    'criteria',
    'clearSearch',
    'resultsSection'
  ];

  connect() {
    this.navKey, this.getCheck = null
  }

  handleKeyDown(event) {
    this.navKey = (event.which >= 37 && event.which <= 40)
    if (this.navKey) {
      this.getCheck = $("input:radio[name='" + event.target.name + "']:checked")[0]
    } else if (event.which == 13) {
      $(event.target).click()
    }
  }

  handleKeyUp(event) {
    this.navKey = null
    this.getCheck = null
  }

  resetForm() {
    $(this.resultsSectionTarget).html('')
    $(this.criteriaTarget).val('').focus()
    this.searchWrapperTarget.scrollIntoView({ behavior: 'smooth' })
  }

  submitStart() {
    BigSpinner.add()
  }
  
  submitEnd() {
    this.searchWrapperTarget.scrollIntoView()
    this.criteriaTarget.blur()
    BigSpinner.finished()
  }

  selectProduct(event) {
    if (this.navKey) {
      event.preventDefault()
      event.stopPropagation()
      if (this.getCheck) {
        this.getCheck.checked = true
      } else {
        event.target.checked = false
      }
    } else {
      let product = event.target.closest('.list-group-item')
      $('.search-results .list-group-item').removeClass('list-group-item--selected')
      $(product).addClass('list-group-item--selected')
      $(product).find('.select-product-button')[0].click()
    }
  }
}