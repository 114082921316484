import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'form',
    'info',
    'alert',
    'tableBody',
    'userProviderAssignmentsAccountId',
    'userProviderAssignmentsCriteria'
  ]

  connect() {}

  setAccountToUserProviderAssignmentModal(event) {
    this.userProviderAssignmentsAccountIdTarget.value = event.currentTarget.dataset.accountId
    this.userProviderAssignmentsCriteriaTarget.value = event.currentTarget.dataset.npiNumber == '-' ? '' : event.currentTarget.dataset.npiNumber
    this.formTarget.requestSubmit()
  }

  resetLookupForm() {
    this.formTarget.reset()
    this.infoTarget.style.display = 'block'
    this.alertTarget.innerHTML = ''
    this.tableBodyTarget.innerHTML = ''
  }
}
