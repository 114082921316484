import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {}

  toggleView(event) {
    const card = $(event.currentTarget).parents('.card');
    $('.tooltip').tooltip('hide');
    if (card.find('.card__body').hasClass('d-none')) {
      card.find('.control__expand').addClass('d-none');
      card.find('.control__collapse').removeClass('d-none');
      card.find('.card__body').removeClass('d-none');
      card.find('.control__collapse').trigger('focus')
    } else {
      card.find('.control__expand').removeClass('d-none');
      card.find('.control__collapse').addClass('d-none');
      card.find('.card__body').addClass('d-none');
      card.find('.control__expand').trigger('focus')
    }
  }
}