import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const elementData = {}
    Object.assign(elementData, this.element.dataset)
    if(elementData.delay) {
      elementData.delay = JSON.parse(elementData.delay)
    }
    if(elementData.html) {
      elementData.html = JSON.parse(elementData.html)
    }
    $(this.element).tooltip(elementData)
  }
}
