import('./window.js')
import('./add_bootstrap_4.js')
import('./jquery_ujs')
import('@app/shared.js')
import('@app/auth/auth.coffee')
import('@app/auth_layout.js')
import('@app/spinner-jquery')
import('@app/modal_attachment_uploader.coffee')

import('@app/controllers/index.js')
