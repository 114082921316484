import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = [
        'container',
        'everyone'
    ]

    forEveryInputExceptTargetEveryone(func) {
        this.containerTarget.querySelectorAll('input').forEach((_input) => {
            let _inputName = _input.name.match(/\[(.*?)\]/)[1]
            let input = document.getElementById('announcement_'+_inputName)

            if(_inputName != "target_everyone") {
                func(input)
            }
        })
    }

    toggleTargetEveryone() {
        this.forEveryInputExceptTargetEveryone((input) => {
            if(this.everyoneTarget.checked) {
                input.checked = false
                input.disabled = true
             } else {
                input.disabled = false
            }
        })
    }
}
