import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  sameDayAuth(event) {
    let fetchParams = `same_day_auth=${event.currentTarget.checked}`

    fetch(`/admin/patient_agreements/${event.currentTarget.dataset['patientAgreementId']}/update_same_day_auth?${fetchParams}`, {
      method: 'PATCH'
    })
  }
}
