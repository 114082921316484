import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "input"
  ]

  connect() {
  }

  refresh(event) {
    let val = event.target.value;
    if(val=="custom") {
      this.inputTarget.classList.remove("d-none")
    } else {
      this.inputTarget.classList.add("d-none")
    }
  }
}
