import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

   static targets = [
     'deleteStockRecordCheckbox',
     'deleteStockRecord',
     'createBatchesBtn',
     'rowCount',
     'rowCountLabel',
     'parLevelHeader',
     'stockCountHeader',
     'parLevel',
     'stockCount',
     'row',
     'uploadContainer',
     'formWrapper',
     'uploadWrapper',
     'parLevelUpdateOnlyCheckbox',
     'parLevelUpdate'
   ]

   static values = {
    uploadPath: String,
    confirmMessage: String,
    deleteStockRecordsMessage: String,
    parLevelMessage: String
  }

  connect() {
    this.setupListeners()
  }

  toggleParLevelUpdate() {
    if (this.parLevelUpdateOnlyCheckboxTarget.checked) {
      if (this.hasCreateBatchesBtnTarget) {
        this.createBatchesBtnTarget.dataset.confirm = this.confirmMessageValue
      }
      this.deleteStockRecordTarget.value = 'false'
      $('#delete_stock_records_checkbox').prop('disabled', true)
      this.parLevelUpdateTarget.value = 'true'
      this.stockCountHeaderTarget.hidden = 'true'
      this.stockCountTargets.forEach(element => { element.hidden = 'true' })
    } else {
      $('#delete_stock_records_checkbox').removeAttr('disabled')
      this.parLevelUpdateTarget.value = 'false'
      this.stockCountHeaderTarget.hidden = null
      this.stockCountTargets.forEach(element => { element.hidden = null })
    }
  }

  alertMessage() {
    if (this.parLevelUpdateOnlyCheckboxTarget.checked) {
      alert(this.parLevelMessageValue)
    } else if (this.deleteStockRecordCheckboxTarget.checked) {
      alert(this.deleteStockRecordsMessageValue)
    }
  }

  toggleDeleteStockRecords() {
    if(!this.hasDeleteStockRecordCheckboxTarget) { return; }

    if (this.deleteStockRecordCheckboxTarget.checked) {
      if (this.hasCreateBatchesBtnTarget) {
        this.createBatchesBtnTarget.dataset.confirm = this.confirmMessageValue
      }
      this.parLevelUpdateTarget.value = 'false'
      $('#par_level_update_only_checkbox').prop('disabled', true)
      this.deleteStockRecordTarget.value = 'true'
      this.parLevelHeaderTarget.hidden = 'true'
      this.stockCountHeaderTarget.hidden = 'true'

      this.parLevelTargets.forEach(element => { element.hidden = 'true' });
      this.stockCountTargets.forEach(element => { element.hidden = 'true' });
    } else {
      $('#par_level_update_only_checkbox').removeAttr('disabled')
      this.deleteStockRecordTarget.value = 'false'
      this.parLevelHeaderTarget.hidden = null
      this.stockCountHeaderTarget.hidden = null

      this.parLevelTargets.forEach(element => { element.hidden = null });
      this.stockCountTargets.forEach(element => { element.hidden = null });
    }
  }

  // START FORM

  setupListeners() {
    $(document).on('keydown', this.keyDownHandler.bind(this))
    $(document).on('keyup', this.keyUpHandler.bind(this))
    $('#clipboard').on('blur', this.pasteHandler.bind(this))
    this.headers = this.formWrapperTarget.innerHTML
  }

  keyDownHandler(event) {
    this.copyPaste = false
    if (event.ctrlKey || event.metaKey) {
      this.copyPaste = true
      $('#clipboard').trigger('focus').trigger('select')
    }
  }

  keyUpHandler(event) {
    if(this.copyPaste) $('#clipboard').trigger('blur')
  }

  pasteHandler(event) {
    let raw = $('#clipboard').val()

    if(raw.length > 0){
      this.renderForm()
      $('#upload-instructions').remove()

      let lineItems = this.rawToLines(raw)
      let rows = this.linesToRows(lineItems)
      let attributes = Array.from(this.element.querySelectorAll(".col-header")).map((column) => {
        return column.getAttribute("data-attribute-name");
      });

      rows.forEach((row) => {
        let cells = {};
        attributes.forEach((attribute, index) => {
          cells[attribute] = row[index];
        });

        this.renderRow(cells);
      });

      if (this.hasDeleteStockRecordCheckboxTarget && this.deleteStockRecordCheckboxTarget.checked)
        this.toggleDeleteStockRecords()
      if (this.hasParLevelUpdateOnlyCheckboxTarget && this.parLevelUpdateOnlyCheckboxTarget.checked)
        this.toggleParLevelUpdate()
    }
    this.updateCount()
  }

  renderForm() {
    let template = HandlebarsTemplates["mass_entry/form"]
    let uploadPath = this.uploadPathValue
    let headers = this.headers

    this.formWrapperTarget.innerHTML = template({ uploadPath, headers })
  }

  rawToLines(value) {
    return value.split(/\n/)
  }

  linesToRows(lines) {
    let splittabs = (line) => line.split(/\t/);
    let isblankrow = (cells) => cells.some(cell => cell.trim().length > 0);

    return _.chain(lines).map(splittabs).filter(isblankrow).value();
  }

  updateCount() {
    this.rowCountTarget.innerHTML = this.rowTargets.length

    if(this.rowTargets.length > 1) {
      this.rowCountLabelTarget.innerHTML = 'Rows'
    } else {
      this.rowCountLabelTarget.innerHTML = 'Row'
    }
  }

  // END FORM
  // START ROW

  renderRow(cells) {
    let template = HandlebarsTemplates["mass_entry/row"]

    this.uploadWrapperTarget.insertAdjacentHTML('beforeend', template({ cells }))
  }

  destroyRow(event) {
    event.currentTarget.parentElement.parentElement.remove()

    this.updateCount()
  }

  // END ROW
}
