import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'modalForm',
    'alertBlock',
    'savedSearchName'
  ]

  static values = {
    controllerName: String
  }

  connect() {
    this.setSavedSearchParams()
    this.paramSavedSearchId = this.urlParams.get('saved_search')
  }

  setSavedSearchParams() {
    let url = new URL(window.location.href);
    this.urlParams = new URLSearchParams(url.search);
  }

  prepareParams() {
    this.setSavedSearchParams()
    this.urlParams.delete('saved_search')
  }

  newForm() {
    this.prepareParams()

    let fetchParams = `saved_search[search_type]=${this.controllerNameValue}&saved_search[params]=?${encodeURIComponent(this.urlParams.toString())}`
    fetch(`/admin/saved_searches/new?${fetchParams}`, { method: 'GET' })
    .then(response => response.text())
    .then(function(partial) {
      this.modalFormTarget.innerHTML = partial
    }.bind(this))
    .catch(error => { console.error('Error:', error); })
  }

  editForm() {
    this.prepareParams()

    let fetchParams = `saved_search[search_type]=${this.controllerNameValue}&saved_search[params]=?${encodeURIComponent(this.urlParams.toString())}`
    fetch(`/admin/saved_searches/${this.paramSavedSearchId}/edit?${fetchParams}`, { method: 'GET' })
    .then(response => response.text())
    .then(function(partial) {
      this.modalFormTarget.innerHTML = partial
    }.bind(this))
    .catch(error => { console.error('Error:', error); })
  }

  submitForm(){
    if (this.savedSearchNameTarget.value == '') {
      event.preventDefault()
      this.alertBlockTarget.firstElementChild.innerHTML = "Preset name can't be blank"
      this.alertBlockTarget.classList.remove('d-none')
    }
  }
}
