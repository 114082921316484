import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["errors", "redirect", "npi"]

  resetForm() {
    $(this.element).find(".modal-content").html($("#new-provider-modal-template").html())
  }

  redirectTargetConnected() {
    window.location.href = this.redirectTarget.dataset.url
  }

  import(event) {
    let npi = this.npiTarget.value
    get(event.target.dataset.url, {
      query: {npi_number: npi}, responseKind: "turbo-stream"
    })
  }

  scrollErrorsIntoViewTargetConnected() {
    $(this.errorsTarget).removeClass("d-none")
    this.errorsTarget.scrollIntoView()
  }
}
