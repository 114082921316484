import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'patientPathway',
    'surgeryDateContainer',
    'surgeryDate',
    'sendDataTooltip',
    'submitBtn'
  ]

  connect() {
    this.updateSubmitBtn()
    this.manageSurgeryDate()
  }

  updateSubmitBtn() {
    this.submitBtnTarget.disabled = true
    $(this.sendDataTooltipTarget).tooltip('enable')

    if (this.patientPathwayTarget.value != '' && this.needsAndCompleteSurgeryDate()) {
      $(this.sendDataTooltipTarget).tooltip('disable')
      this.submitBtnTarget.disabled = false
    }
  }

  manageSurgeryDate() {
    if (document.querySelector('#surgery_date').innerHTML !== '-') return;

    if (this.patientPathwayTarget.querySelector(':checked').getAttribute("data-pathway-type") == 'Surgical') {
      this.surgeryDateContainerTarget.classList.remove('d-none')
    } else {
      this.surgeryDateContainerTarget.classList.add('d-none')
    }
  }

  needsAndCompleteSurgeryDate() {
    return this.patientPathwayTarget.querySelector(':checked').getAttribute("data-pathway-type") != 'Surgical' ||
      (
        this.surgeryDateTargets[0].value != '' &&
        this.surgeryDateTargets[1].value != '' &&
        this.surgeryDateTargets[2].value != ''
      )
  }
}
