import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'editPresetBtn'
  ]

  static outlets = [
    'saved-searches-form'
  ]

  connect() {
    this.disableEditPresetBtn()
  }

  openNewPreset() {
    this.savedSearchesFormOutlet.newForm()
    $("#saved-searches-modal").modal("show")
  }

  openEditPreset() {
    this.savedSearchesFormOutlet.editForm()
    $("#saved-searches-modal").modal("show")
  }


  disableEditPresetBtn() {
    if (this.savedSearchesFormOutlet.paramSavedSearchId == null) {
      this.editPresetBtnTarget.disabled = true
    }
  }

}
