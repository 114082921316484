import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["chatFormField"]

  connect() {
    this.setupLiveChatListeners()
  }

  setupLiveChatListeners() {
    if(this.element.id != 'live-chat') {
      return
    }
    //Hiding the minimized custom launcher in the bottom right when live chat widget is visible
    zE("messenger:on", "open", function () {
      document.getElementById('live-chat').classList.add('d-none')
    })
    
    //Showing the minimized custom launcher in the bottom right when live chat widget is closed
    zE('messenger:on', 'close', function () {
      const chatSelector = document.getElementById('live-chat')
      chatSelector.querySelector('.live-chat__count').innerHTML = 0
      chatSelector.classList.remove('d-none')
    })

    //When unread messages exist, minimized live chat turns red and updates count
    zE('messenger:on', 'unreadMessages', function (count) {
      const chatSelector = document.getElementById('live-chat')
      if (count > 0) {
        chatSelector.querySelector('.live-chat__count').innerHTML = count
        chatSelector.classList.add('live-chat--unread')
        chatSelector.classList.remove('d-none')
      } else {
        chatSelector.classList.remove('live-chat--unread')
      }
    })
  }

  openLiveChat() {
    $('.modal').modal('hide') // Hide all active modals when opening live chat

    let conversationFields = this.chatFormFieldTargets.map(target => {return {id: target.dataset.zendeskUid, value: target.value}})

    zE("messenger:set", "conversationFields", conversationFields).then(function () {
      zE('messenger:set', 'conversationTags', ['chat_message'])
      zE('messenger', 'show')
      zE('messenger', 'open')
    })
  }

  closeLiveChat() {
    zE('messenger', 'close')
  }
}
